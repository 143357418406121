<template functional>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9965 11.0032L13.0036 4.92913C13.0036 4.66283 12.8978 4.40743 12.7095 4.21913C12.5212 4.03083 12.2658 3.92504 11.9995 3.92504C11.7332 3.92504 11.4778 4.03083 11.2895 4.21913C11.1012 4.40743 10.9954 4.66283 10.9954 4.92913L11.0025 11.0032L4.92841 10.9961C4.66211 10.9961 4.40671 11.1019 4.21841 11.2902C4.03011 11.4785 3.92432 11.7339 3.92432 12.0002C3.92432 12.2665 4.03011 12.5219 4.21841 12.7102C4.40671 12.8985 4.66211 13.0043 4.92841 13.0043L11.0025 12.9972L10.9954 19.0713C10.9948 19.2033 11.0204 19.3341 11.0707 19.4562C11.121 19.5782 11.1949 19.6891 11.2883 19.7825C11.3816 19.8758 11.4925 19.9498 11.6146 20C11.7367 20.0503 11.8675 20.0759 11.9995 20.0754C12.1315 20.0759 12.2623 20.0503 12.3844 20C12.5064 19.9498 12.6173 19.8758 12.7107 19.7825C12.804 19.6891 12.878 19.5782 12.9282 19.4562C12.9785 19.3341 13.0041 19.2033 13.0036 19.0713L12.9965 12.9972L19.0705 13.0043C19.2026 13.0048 19.3334 12.9792 19.4554 12.929C19.5775 12.8787 19.6884 12.8048 19.7818 12.7114C19.8751 12.6181 19.949 12.5072 19.9993 12.3851C20.0496 12.263 20.0752 12.1322 20.0746 12.0002C20.0752 11.8682 20.0496 11.7374 19.9993 11.6153C19.949 11.4932 19.8751 11.3823 19.7818 11.289C19.6884 11.1956 19.5775 11.1217 19.4554 11.0714C19.3334 11.0212 19.2026 10.9956 19.0705 10.9961L12.9965 11.0032Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'IconPlusAddToList'
}
</script>

<style>
</style>
