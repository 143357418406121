<template lang="pug">
.create-matrix__body
  .create-matrix__title {{ 'ui.dropdown.select_skills' | translate }}
  v-radio-group.create-matrix--list(v-model="radio")
    .create-matrix--item
      v-radio(
        :id="0",
        :label="$t('pages.skill_gap.select_all_required_skills')"
      )
    .create-matrix--item
      v-radio(:id="1", :label="$t('pages.skill_gap.select_unique_skills')")
      template(v-if="radio === 1")
        v-dropdown(:focusSwitchEL="focusSearch")
          v-input-search.input-search(
            :placeholder="$t('ui.inputs.search_skills')",
            type="text",
            :auto-focus="false",
            @focus="focusSearch = $event",
            v-model="searchSkills"
          )
          template(slot="content")
            v-card(elevation)
              v-scrollable(updateble, :min-height="20", :bottom-offset="15")
                template
                  v-list
                    v-list-item(
                      v-for="item in filterBadges",
                      :key="item.id",
                      @click="!selected.find((el) => el.id === item.id) ? selectSkills(item) : removeSkills(item)"
                    )
                      slot(name="icon")
                        badge(
                          :width="24",
                          :height="32",
                          :level="item.starsCount",
                          :color="item.color"
                        )
                      v-list-item-content
                        v-list-item-title
                          span {{ item.displayName }}
                      v-list-item-actions
                        v-btn(
                          v-if="!selected.find((el) => el.id === item.id)",
                          icon,
                          success
                        )
                          icon-plus
                        v-btn(v-else, icon, danger)
                          icon-minus
                    v-list-empty(v-if="!filterBadges.length")
                      i.icon.icon--sm.icon--empty
                      span {{ 'ui.labels.list_is_empty' | translate }}
        v-scrollable.create-matrix__content(
          v-if="selected.length",
          updateble,
          :min-height="20"
        )
          .create-matrix__baguettes
            v-baguette(v-for="badge in selected", :key="badge.id")
              icon-badge
              span {{ badge.displayName }}
              v-btn(paddingless, text, @click="removeSkills(badge)")
                icon-close.m-0
</template>

<script>
import api from '@/api'
import IconPlus from '@/components/svg/IconPlusAddToList.vue'
import IconMinus from '@/components/svg/icons/IconMinus.vue'
import IconClose from '@/components/svg/VInputSearch_CloseIcon'
import Badge from '@/components/svg/Badge.vue'
import IconBadge from '@//components/svg/skill-matrix/IconBadge.vue'

export default {
  name: 'SelectSkillsStep',
  components: { IconPlus, IconMinus, IconClose, Badge, IconBadge },

  data: () => ({
    radio: 0,
    badges: [],
    matrix: [],
    selected: [],
    searchSkills: '',
    focusSearch: false
  }),

  watch: {
    selected () {
      this.$emit('select', { badges: this.selected })
    },

    radio (value) {
      this.sendRadio(value)
    }
  },
  computed: {
    filterBadges () {
      return this.badges.filter((el) =>
        el.displayName.toUpperCase().includes(this.searchSkills.toUpperCase())
      )
    }
  },

  methods: {
    async loadMatrixList () {
      const { data } = await api.matrix.list()
      this.matrix = data
    },

    async loadBadgesList () {
      const params = {
        noCategories: 1,
        sort: 'name',
        all: true
      }
      const { data } = await api.badges.list(params)
      this.badges = data
    },

    selectSkills (item) {
      this.selected.push(item)
    },

    removeSkills (item) {
      const index = this.selected.findIndex((el) => el.id === item.id)
      this.selected.splice(index, 1)
    },
    sendRadio (value) {
      switch (value) {
        case 0:
          this.$emit('select', { isSelectProfileSkills: true })
          break
        case 1:
          this.$emit('select', { isSelectProfileSkills: false })
          break
      }
    }
  },

  mounted () {
    this.loadMatrixList()
    this.loadBadgesList()
    this.sendRadio(this.radio)
  }
}
</script>

<style lang="scss" scoped>
.create-matrix {
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: $h2-title;
    margin-bottom: 24px;
  }
  &__content {
    flex: 1 0 auto;
    margin-top: 16px;
    max-height: 192px;
  }

  &--list {
    display: flex;
    flex-direction: column;
  }
  &--item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .v-dropdown {
      margin-top: 8px;
      padding-left: 40px;
      ::v-deep .v-dropdown__content {
        width: calc(100% - 40px);
        left: auto;
      }
      .v-card {
        padding: 8px 4px 8px 16px;
      }
      ::v-deep .v-list {
        padding-right: 20px;
      }
      ::v-deep .v-list__item {
        padding: 12px 8px;
        height: 48px;
        gap: 16px;
      }
      ::v-deep .v-list__item-title {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $body-text;
      }
      ::v-deep .v-btn--icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
