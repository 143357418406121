<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :class="[data.class, data.staticClass]"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9969 11.003L13.0004 10.9997L11.9999 10.9959L12.0004 10.9997L4.92879 10.9959C4.66249 10.9959 4.40709 11.1017 4.21879 11.29C4.03049 11.4783 3.9247 11.7337 3.9247 12C3.9247 12.2663 4.03049 12.5217 4.21879 12.71C4.40709 12.8983 4.66249 13.0041 4.92879 13.0041L12.0004 12.9998L19.0709 13.0041C19.2029 13.0046 19.3337 12.979 19.4558 12.9288C19.5779 12.8785 19.6888 12.8046 19.7821 12.7112C19.8755 12.6179 19.9494 12.507 19.9997 12.3849C20.05 12.2628 20.0756 12.132 20.075 12C20.0756 11.868 20.05 11.7372 19.9997 11.6151C19.9494 11.493 19.8755 11.3821 19.7821 11.2888C19.6888 11.1954 19.5779 11.1215 19.4558 11.0712C19.3337 11.021 19.2029 10.9954 19.0709 10.9959L12.9969 11.003Z"
      fill="#F85061"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMinus'
}
</script>
